import React from "react";
import Layout from "../../layout";
import BasicExample from "./accordion/accordion";

export default function Career() {
  return (
    <>
      <Layout>
        <BasicExample />
      </Layout>
    </>
  );
}
