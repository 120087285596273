import React from 'react'
import StickyFooter from './Stickyfooter'
import MainFooter from './MainFooter'
import './footer.css'

const Footer = () => {
  return (<>
    <MainFooter />
    <StickyFooter />
    </>
  )
}

export default Footer